var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "in-layout" },
    [
      _c(
        "v-toolbar",
        { staticClass: "header", attrs: { color: "grey lighten-3", flat: "" } },
        [
          _c(
            "v-container",
            { staticClass: "py-3", attrs: { fluid: "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "" } },
                [_c("ima-logo"), _c("v-spacer")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-container",
        { attrs: { "text-xs-center": "" } },
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [_c("v-flex", { attrs: { xs12: "" } }, [_vm._t("content")], 2)],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }