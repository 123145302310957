var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "verification-layout",
    [
      _c(
        "template",
        { slot: "content" },
        [
          _c(
            "div",
            {
              staticClass: "elevation-5 mx-auto my-5",
              staticStyle: { width: "1024px" },
            },
            [
              _c(
                "v-dialog",
                {
                  attrs: { width: "500", persistent: "" },
                  model: {
                    value: _vm.recaptchDialog,
                    callback: function ($$v) {
                      _vm.recaptchDialog = $$v
                    },
                    expression: "recaptchDialog",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-form",
                        {
                          model: {
                            value: _vm.valid,
                            callback: function ($$v) {
                              _vm.valid = $$v
                            },
                            expression: "valid",
                          },
                        },
                        [
                          _c(
                            "v-card-text",
                            { staticClass: "recaptcha-container" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Nº do MTR",
                                  required: "",
                                  rules: [_vm.validationRules.required],
                                },
                                model: {
                                  value: _vm.mtrNumber,
                                  callback: function ($$v) {
                                    _vm.mtrNumber = $$v
                                  },
                                  expression: "mtrNumber",
                                },
                              }),
                              _c("vue-recaptcha", {
                                ref: "recaptcha",
                                attrs: {
                                  sitekey: _vm.recaptchaPublicKey,
                                  loadRecaptchaScript: true,
                                },
                                on: { verify: _vm.onVerify },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-card-actions",
                            { staticClass: "justify-end" },
                            [
                              _c("v-btn", { attrs: { to: "/login" } }, [
                                _vm._v(
                                  "\n              Cancelar\n            "
                                ),
                              ]),
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    disabled:
                                      !_vm.valid ||
                                      _vm.gTokenRecaptcha === null,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.previewMtr()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              Consultar\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              !_vm.recaptchDialog && _vm.pdfLink
                ? _c("pdf", {
                    staticStyle: { width: "100%" },
                    attrs: { src: _vm.pdfLink },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { "hide-overlay": "", persistent: "", width: "300" },
              model: {
                value: _vm.loadingDialog,
                callback: function ($$v) {
                  _vm.loadingDialog = $$v
                },
                expression: "loadingDialog",
              },
            },
            [
              _c(
                "v-card",
                { attrs: { color: "primary", dark: "" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _vm._v(
                        "\n          Aguarde, estamos carregando o MTR\n          "
                      ),
                      _c("v-progress-linear", {
                        staticClass: "mb-0",
                        attrs: { indeterminate: "", color: "white" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }