<template>
  <section class="in-layout">
    <v-toolbar class="header" color="grey lighten-3" flat>
      <v-container class="py-3" fluid>
        <v-layout row>
          <ima-logo />
          <v-spacer />
        </v-layout>
      </v-container>
    </v-toolbar>

    <v-container text-xs-center>
      <v-layout row wrap>
        <v-flex xs12>
          <slot name="content" />
        </v-flex>
      </v-layout>
    </v-container>

  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import ImaLogo from '@/components/base/ImaLogo.vue';
import MtrBrand from '@/components/base/MtrBrand.vue';
import NavigationHeader from '@/components/base/NavigationHeader.vue';
import NotificationDropdown from '@/components/base/NotificationDropdown.vue';
import AccountDropdown from '@/components/base/AccountDropdown.vue';
import NavigationBreadcrumb from '@/components/base/NavigationBreadcrumb.vue';

export default {
  name: 'InLayout',
  components: {
    ImaLogo,
    MtrBrand,
    NavigationHeader,
    NotificationDropdown,
    AccountDropdown,
    NavigationBreadcrumb,
  },
  computed: {
    ...mapGetters({
      navItems: 'application/getMenuItems',
    }),
  },
  created() {
    this.$store.dispatch('application/loadMenuItems');
  },
};
</script>

<style lang="scss">
.in-layout {
  > .header {
    min-height: 80px;
    background: url("../assets/topbar-background.png") left no-repeat;
  }
}

.list-tabs {
  > .v-tabs__bar {
    & {
      background: none;
    }

    .v-tabs__wrapper {
      z-index: 2;
      position: relative;
      overflow: visible;

      &:after {
        z-index: 3;
        display: block;
        position: absolute;
        left: 0;
        bottom: -15px;
        width: 100%;
        height: 15px;
        background: #fff;
        content: "";
        transition: bottom ease .5s;
        transition-delay: .15s;
      }
    }

    .v-tabs__item {
      & {
        position: relative;
        padding: .5rem 1.5rem;
        background: #e0e0e0;
        font-size: 1.2rem;
        border-radius: 3px 3px 0 0;
      }

      &.v-tabs__item--active {
        & {
          background: #fff;
          box-shadow: 0 3px 15px rgba(0,0,0,.20);
        }
      }
    }
  }

  > .v-tabs__items {
    & {
      overflow: visible;
    }

    .v-tabs__content {
      transition: none !important;
    }
  }
}
</style>
