<template>
  <verification-layout>
    <template slot="content">
      <div
        style="width: 1024px"
        class="elevation-5 mx-auto my-5"
      >

        <v-dialog
          v-model="recaptchDialog"
          width="500"
          persistent
        >
          <v-card>
            <v-form
              v-model="valid"
            >
              <v-card-text
                class="recaptcha-container"
              >
                <v-text-field
                  label="Nº do MTR"
                  v-model="mtrNumber"
                  required
                  :rules="[validationRules.required]"
                >

                </v-text-field>

                <vue-recaptcha
                  ref="recaptcha"
                  :sitekey="recaptchaPublicKey"
                  @verify="onVerify"
                  :loadRecaptchaScript="true">
                </vue-recaptcha>

              </v-card-text>

              <v-card-actions
              class="justify-end"
            >
              <v-btn
                :to="'/login'"
              >
                Cancelar
              </v-btn>
              <v-spacer/>
              <v-btn
                color="primary"
                @click="previewMtr();"
                :disabled="!valid || gTokenRecaptcha === null"
              >
                Consultar
              </v-btn>
            </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>

        <pdf
          v-if="!recaptchDialog && pdfLink"
          :src="pdfLink"
          style="width: 100%"></pdf>
      </div>
      <v-dialog
        v-model="loadingDialog"
        hide-overlay
        persistent
        width="300"
      >
        <v-card
          color="primary"
          dark
        >
          <v-card-text>
            Aguarde, estamos carregando o MTR
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>


  </verification-layout>
</template>

<script>
import mtrService from '@/services/mtr-service';
import pdf from 'vue-pdf';
import VueRecaptcha from 'vue-recaptcha';
import VerificationLayout from '@/layouts/VerificationLayout';
import validationRules from '@/mixins/validation-rules';

export default {
  name: 'VerificaMtr',
  components: {
    VueRecaptcha,
    pdf,
    VerificationLayout,
  },
  data() {
    return {
      validationRules,
      mtrNumber: this.$route.query.mtr,
      pdfLink: null,
      recaptchDialog: true,
      loadingDialog: false,
      gTokenRecaptcha: null,
      valid: false,
    };
  },
  methods: {
    onVerify(response) {
      this.gTokenRecaptcha = response;
    },
    previewMtr() {
      this.recaptchDialog = false;
      this.loadingDialog = true;
      mtrService
        .qrcodePreview(this.mtrNumber, this.gTokenRecaptcha)
        .then((response) => {
          this.pdfLink = response.data;
        })
        .catch(() => {
          this.reset();
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'error',
            message: 'Número de MTR inválido. Verique o número e tente novamente',
            messageIcon: 'mdi-alert-outline',
          });
        })
        .finally(() => {
          this.loadingDialog = false;
        });
    },
    reset() {
      this.recaptchDialog = true;
      this.loadingDialog = false;
      this.$refs.recaptcha.reset();
    },
  },
  computed: {
    recaptchaPublicKey() {
      return process.env.VUE_APP_RECAPTCHA_PUBLIC_KEY;
    },
  },
};
</script>

<style lang="scss">
 .recaptcha-container {
  & > div{
    & > div {
      margin: 0 auto;
    }
  }
 }
</style>
